import analyticsApi from "@/apis/analytics";

const state = {
  analyses: []
};

const getters = {
  analyses(state) {
    return state.analyses;
  },
  analysesDates(state) {
    return state.analyses.map(analysis => analysis.date);
  },
  analysesMappingArray(state) {
    let analysesMappingArray = {};
    const analysesKeys = Object.keys(state.analyses[0] || {});

    analysesKeys.forEach(analysesKey => {
      analysesMappingArray[analysesKey] = state.analyses.map(
        analysis => analysis[analysesKey]
      );
    });

    return analysesMappingArray;
  }
};

const mutations = {
  setAnalyses(state, analyses) {
    state.analyses = analyses;
  }
};

const actions = {
  async fetchAnalytics({ commit }, payload) {
    const { analyses } = await analyticsApi.getAnalytics(payload);
    const { analysis } = await analyticsApi.getTodayAnalytics(payload);
    commit("setAnalyses", [...analyses, analysis]);
  },
  async fetchTransactionAnalytics({ commit }, payload) {
    const { analyses } = await analyticsApi.getTransactionAnalytics(payload);
    const { analysis } = await analyticsApi.getTodayTransactionAnalytics(
      payload
    );
    commit("setAnalyses", [...analyses, analysis]);
  },
  async fetchOrderAnalytics({ commit }, payload) {
    const { analyses } = await analyticsApi.getOrderAnalytics(payload);
    const { analysis } = await analyticsApi.getTodayOrderAnalytics(payload);
    commit("setAnalyses", [...analyses, analysis]);
  },
  async fetchSubsAnalytics({ commit }, payload) {
    const { subscription_analyses } = await analyticsApi.getSubsAnalytics(
      payload
    );
    commit("setAnalyses", [...subscription_analyses]);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
